<template>
  <b-modal
    id="modal-image"
    centered
    hide-header
    hide-footer
    body-class="p-4"
  > 
    <template v-if="items">
      <b-link :href="items[currentIndex]" target="_blank">
        <b-img :src="items[currentIndex]" alt="" fluid-grow />
      </b-link>
      <b class="d-block text-center mt-1">{{ currentIndex + 1 }}/{{ items.length }}</b>
      <!-- <div class="review-image-container mt-1">
        <b-img
          :src="reviewImage"
          class="rounded cursor-pointer col-review-image"
          :class="{'active': currentIndex == i}"
          @click="currentIndex = i"
          v-for="(reviewImage, i) in items"
          :key="i"
        />
      </div> -->
      <b-row no-gutters class="mt-1">
        <div class="col-review-image pr-1" v-for="(reviewImage, i) in items" :key="i">
          <b-img
            :src="reviewImage"
            alt=""
            class="rounded cursor-pointer"
            :class="{'active': currentIndex == i}"
            @click="currentIndex = i"
          />
        </div>
      </b-row>
    </template>
  </b-modal>
</template>

<script>
export default {
  name: "ModalImage",    
  props: ["items", "index"],
  data() {
    return {
      currentIndex: 0,
    }
  },
  watch: {
    index(val) {
      this.currentIndex = val;
    }
  },
};
</script>
<style>
.review-image-container {
  display: flex;
  column-gap: 6px;
}
.col-review-image img {
  border: 1px solid #f1f1f1;
}
.col-review-image img.active {
  border: 1px solid #d89700;
}
.col-review-image {
  flex: 0 0 20%;
  max-width: 20%;
}
</style>